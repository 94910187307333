<template>
  <div class="settings">
    <div class="mt-5 px-12 mb-10">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import { mdiPlus } from '@mdi/js'

export default {
  name: 'ProgramsPage',

  components: {},

  data() {
    return {
      addNew: false,
      icons: {
        add: mdiPlus,
      },
    }
  },
}
</script>
